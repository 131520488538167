<template>
  <div>
    <MyHeader :title="$t('lang.menu_userregister')" ShowBack />
    <van-form @submit="onSubmit">
      <van-cell-group :title="$t('lang.personal_info')">
        <van-field
          v-model="mCompany"
          name="fCompany"
          required
          readonly
          :label="$t('lang.companyname')"
          :placeholder="$t('lang.companyname_input')"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="mName"
          name="fName"
          required
          clearable
          :label="$t('lang.compellation')"
          :placeholder="$t('lang.compellation_input')"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="mUserName"
          name="fUserName"
          required
          clearable
          :label="$t('lang.username')"
          :placeholder="$t('lang.username_input')"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="mPwd1"
          name="fPwd1"
          required
          type="password"
          clearable
          :label="$t('lang.password')"
          :placeholder="$t('lang.password_input')"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="mPwd2"
          name="fPwd2"
          required
          type="password"
          clearable
          :label="$t('lang.repwd')"
          :placeholder="$t('lang.repwd_input')"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="mCode"
          name="fCode"
          required
          center
          clearable
          :label="$t('lang.captcha')"
          :placeholder="$t('lang.captcha')"
          maxlength="4"
          :rules="[{ required: true }]"
        >
          <template #button>
            <van-image
              width="130"
              height="38"
              :src="mCaptcha"
              @click="getCaptcha"
            />
          </template>
        </van-field>
      </van-cell-group>

      <van-cell-group :title="$t('lang.moreinfo')">
        <van-field name="fSex" :label="$t('lang.gender')">
          <template #input>
            <van-radio-group v-model="mSex" direction="horizontal">
              <van-radio name="1">{{ $t("lang.male") }}</van-radio>
              <van-radio name="2">{{ $t("lang.female") }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="mTel"
          name="fTel"
          type="tel"
          clearable
          :label="$t('lang.phone')"
          :placeholder="$t('lang.phone_input')"
        />
        <van-field
          v-model="mEmail"
          name="fEmail"
          clearable
          :label="$t('lang.email')"
          :placeholder="$t('lang.email_input')"
        />
        <van-field
          v-model="mNumber"
          name="fNumber"
          clearable
          :label="$t('lang.jobnumber')"
          :placeholder="$t('lang.jobnumber_input')"
        />
        <van-field
          v-model="mPosition"
          name="fPosition"
          clearable
          :label="$t('lang.position')"
          :placeholder="$t('lang.position_input')"
        />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button block color="#669999" native-type="submit">
          {{ $t("lang.submit") }}
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import MyHeader from "@/components/Header.vue";
import { GetCaptcha, Register, GetCompanyName } from "@/api/index.js";
import { Dialog } from "vant";

export default {
  components: { MyHeader },
  data() {
    return {
      mCompany: "",
      mCompanyID: "",
      mCaptcha: "",
      mName: "",
      mUserName: "",
      mPwd1: "",
      mPwd2: "",
      mCode: "",
      mSex: "1",
      mTel: "",
      mEmail: "",
      mNumber: "",
      mPosition: "",
    };
  },

  mounted() {
    this.mCompanyID = this.$route.params.cid;
    GetCompanyName(this.mCompanyID, (data) => {
      console.log(data);
      if (data) {
        if (data.code == 0) this.mCompany = data.data[0].companyName;
        else this.mCompany = "";
      } else this.mCompany = "";
    });

    this.getCaptcha();
  },

  methods: {
    getCaptcha() {
      GetCaptcha((ret) => {
        console.log(ret);
        this.verKey = ret.key;
        this.mCaptcha = ret.image;
      });
    },

    onSubmit(fr) {
      console.log(fr);
      let openid = this.$store.state.openid;
      if (openid == undefined || openid == "") {
        this.$Toast(this.$t("lang.wechatopen_hint"));
        return;
      }
      if (fr.fCompany == "") {
        this.$Toast(this.$t("lang.scanqrcode_hint"));
        return;
      }
      if (fr.fPwd1 != fr.fPwd2) {
        this.$Toast(this.$t("lang.differentpwd_hint"));
        return;
      }

      Register(
        {
          openId: openid,
          companyId: this.mCompanyID,
          name: fr.fName,
          sex: fr.fSex,
          phone: fr.fTel,
          email: fr.fEmail,
          jobNumber: fr.fNumber,
          position: fr.fPosition,
          userName: fr.fUserName,
          password: fr.fPwd1,
          captcha: fr.fCode,
          verKey: this.verKey,
        },
        (ret) => {
          //console.log(ret);
          if (ret) {
            if (ret.code == 0) {
              sessionStorage.clear();
              Dialog.alert({
                message: this.$t("lang.register_success"),
              }).then(() => {
                window.location.href = this.$Config.ClientUrl();
              });
            } else this.$Toast(ret.message);
          } else this.$Toast(this.$t("lang.register_fail"));
        }
      );
    },
  },
};
</script>
